*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  



.OuterBox{
    
    height:  100vh;
    width: 100vw;

    display: flex;
    justify-content: center; 
     background-color: #F7E5E9;
}    


.InnerBox-1{

         
    height: 100%;
    width: 47.31%;
    display: flex;
    align-items: flex-end;
  
      
} 


.InnerBox-2{

        
    height:  100%;
    width: 52.69%;
    
   padding-left: 18px; 
   

}  


.description1_login{
  
 height : 12% ; 
 top : 23.95% ;  
 position: absolute;

overflow:  hidden;

}   


.description1-text{
 
    font-size: 55px ;
    font-weight: 800  ;  
    line-height: normal; 
    color: #F06B6D;


}




.input_text1{

height : 8% ;
 width : 20% ;  
 background-color: brown ; 
 border-radius: 20px;
 top : 40.93% ;  
 position: absolute;

}  
 



.input_text2{

    height : 8% ;
     width : 20% ;  
     background-color : #FFFFFF;
     border-radius: 20px;
     top : 51.13% ;  
     position: absolute; 
     display: flex;
     flex-direction: row; 
    
    } 

    


.input_box_text{
     
    width : 100% ; 
    height : 100%;
    background-color: "white"; 
    text-align: center ; 
    border : none  ;  
    border-radius: 20px;
    
    
}  

   


.ab{
 
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px ; 
}  



.login_button{
      
   border: none;
   background-color: #FFFFFF ;

}


    
  
.password-text{

    top : 61.96% ;  
    position: absolute;  
    display:  flex; 
    align-items: center;
    justify-content: flex-start;
}


.button{

    height: 8.05% ; 
    width:  13.5% ;  
    background-color: #5E81F4 ; 
    
    top : 61.96% ;  
     position: absolute;  
     border-radius:  20px; 
     overflow: hidden ; 
     display:  flex; 
     align-items:  center; 
     justify-content:  center;
} 




.button_c {

    border: 0px solid red ;
   font-size: x-small; 
   margin : 4px ; 


}


.description2_login{


    top : 73.38% ;  
    position: absolute;
}

