*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  
  
  
  
  
  .form_outer_div{
  
  height: 100vh ;
  width : 100vw ; 
  display:  flex ;
  background-color: orangered;  
  flex-direction: row;
  
  
  }  
  
  
  .form_outer_div_sidebar{
  
  
    
  width : 19% ; 
  height : 100% ; 
   background-color: #FFF ; 
  
  } 
  
  .form_outer_div_body{
  
   
    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color:  #FFFFFF ;  

  
  } 
   
  .addaccount_form{
    
    margin-top:  5%;
    height : 69.14% ;
    width : 100%  ; 
    display: flex ; 
    flex-direction: column;    
    background-color: #FFFFFF;
    
  }  
   
  
  .addaccount_form_row{
  
      width : 100% ;  
      height: 11.65%;
   
      display:  flex ;  

      flex-direction: row ;
      border:   1px solid #B6B7D0 ;
  
  }  
  
  
  .addaccount_admin_Form-Description{
  
    
    flex : 30% ;   
    display:flex ; 
    align-items: center; 
    padding-left: 5%;  

    
  } 
  
  .addaccount_admin_Form-Input{
      
    flex : 70% ; 
 
   display: flex; 
   align-items:  center ;
   justify-content:center  ;   
   border-left:  1px solid #B6B7D0;
  
  }  
  
  
  .addaccount_admin_input-box{
    


    width : 80% ; 
    height : 80% ;  
    border-radius:  20px;   
    border : 1px solid #5E82F4;


  
  } 


  .addaccount_form_row_btn_div{ 

    width : 100% ;  
    height: 18.45%;

    display:  flex ;  

    flex-direction: row ;
  
    align-items:  flex-end ; 
    padding-bottom:  1.5% ;
    justify-content: flex-end ; 
    padding-right: 4%;

  }

  .addaccount_form_row_btn{

     
    width : 10.96% ; 
    height : 55% ;  
    border-radius:  20px; 
   background-color: #FCC046;
   border: 0px solid red

  }
  
  
  
  
  
  