
*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  



 .clientview{

    height: 100vh ;
    width : 100vw ; 
   display:  flex ;
    background-color: #FFF;
   
   
   }   


.clientview_sidebar{ 
  
  
    
    width : 19% ; 
    height : 100% ; 
     background-color: #FFF ; 
  


} 

.clientview_body{ 
  

    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: #FFF;  
    justify-content:  center;
    align-items: center;

} 


.clientview_body1{

    height:  10% ;
    width : 100%; 
   display:  flex ;
    background-color: #FFF; 
    flex-direction: column; 
    display: flex; 
    align-items: center;
    justify-content: center;


}
  


.clientview_body1_search_div{
  
    width : 50% ; 
    background-color: #FFFFFF;  
    height: 70% ;
    display: flex; 
    align-items: center;
    justify-content: flex-end;
     flex-direction: row;  
     border : 1px solid #000000 ; 
     border-radius: 15px ;



}     



.clientview_body1_search_input{

  

 
     height: 100%;  
     width : 100% ; 
     border : none; 
     border-radius: 15px ;
     padding-left: 10px ; 


}    



.clientview_body1_search_button{

  

 
   
    background-color: #FFFFFF;
    border : 0px red ;
    border-radius:  15px ;

}  

 









.clientview_table_outer_div_body2{
   

    height:  67% ;
    width : 100%; 
   display:  flex ;
    background-color: pink; 
    flex-direction: column;

}  


.clientview_table_inner_div_column_name{
  
    height:  20% ;
    width : 100%;  
    background-color:#D9D9D9; 
    display: flex ;   
    overflow-y:  scroll ; 
     border-bottom : "1px solid red" ; 
    
}   


 
.clientview_table_inner_div_column_name::-webkit-scrollbar{

    width:  8px; 
    background-color: #B6B7D0 ;

}
    




.clientview_table_inner_div_table_row{
  
    height:  80% ;
    width : 100%;  
    background-color:#FFF;
    overflow-y:  scroll ; 

} 
 


.clientview_table_inner_div_table_row::-webkit-scrollbar{

    width:  8px; 
    background-color: #B6B7D0 ;


}
 


.clientview_table_row_button{

   
    background-color:#FCC046  ;

  
}





.clientview_body3{

     
    height:  23% ;
    width : 100%; 
   display:  flex ;
    background-color: #FFF; 
    flex-direction: column; 
    align-items:  flex-end;
 
}
    

.add_new_program_button{
    margin-top:  2%;
    height:  24.82% ;
    width : 18%;  
    background-color: #FCC046 ; 
    margin-right: 5%;
    border-width: 0;
    border-radius:  25px ; 
    display: flex; 
    align-items: center; 
    justify-content: center;
}
  



.clientview_table_row_box{

     

    display: flex ; 
    align-items: center; 
    justify-content: center; 
   
}  





.clientview_table_row_box_scrollable {

    padding :2px ;  
    overflow-y:  scroll ; 
   
}  



.clientview_table_row_box_scrollable::-webkit-scrollbar{

    width:  1px; 
    background-color:  #B6B7D0 ;  
   
  
  
  } 

 


.inner_table_btn{

    background-color: #FCC046;
    border-radius: 20px; 
    border: 0px solid red ;
}



.header_text{


    font-weight:  700;
    font-size:  16px;
    
    font-style: normal;
   }