*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  




.home{

 height: 100vh ;
 width : 100vw ; 
display:  flex ;
 background-color: orangered;


}  


.home-sidebar{
  

    
  width : 19% ; 
  height : 100% ; 
   background-color: #FFF8EE ; 

} 

.home-body{

   
    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: #FFF8EE; 
    justify-content:  center;
    align-items: center;
  
} 


.home-body1{ 
    padding:  10px;
    width : 100% ; 
    height : 43.57%; 
    background-color: #FFF8EE; 
    display: flex ;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 15px;
  
} 

.home-body2{
    
    padding : 10px ; 
    width : 50.67% ; 
    height : 18.51% ; 
    display: flex; 
    justify-content: space-between; 
    background-color: #FFF8EE; 
    align-items: center;

}   

.home-body2-button1{
 
    width :  45.56% ; 
    height : 90.95% ; 
    background-color:  #353B55; 
    border-radius:  25px ; 
    display: flex; 
    align-items: center;
    justify-content: center;

}  


.home-body3{

   width : 100% ; 
   height : 37.92% ; 
   display: flex; 
  justify-content: center;
    background-color: #FFF8EE; ; 
    padding-top:  15px ;
}  


.home-body2-button2{
 
    width :  32.56% ; 
    height : 30.95% ; 
    background-color:  #353B55; 
    border-radius:  25px ;


}


.home-body-inner-description1{

    width : 60% ; 
    height: 43.2% ; 
    background-color: #D9D9D9; 
  
    padding: 1%; 
    border-bottom-left-radius: 25px; 
    border-top-left-radius: 25px; 
    display:  flex; 
    flex-direction:  column; 
    justify-content:  space-around;
} 


.home-body-illustration{
      
    width : 20% ; 
    height: 65.46% ;  
    background-image: linear-gradient(
        to top,
        #D9D9D9 0%,
        #D9D9D9 66%,
        #FFF8EE 54%,
        #FFF8EE 100% 
      );  
     

}

