*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  
  }
  
  
.Sidebar{
    text-align: center; 
    background-color: #5A6199; 
    height:   100% ;  
    width:   100% ;  
    display:  flex ; 
    justify-content: center; 
    flex-direction:  column ; 

  }  



.Sidebar-description{

  height: 55% ;
  width : 100% ; 
  
}
 
 
.Sidebar-Program-Name-div{ 
     
   
  height: 25.45% ;
  width : 100% ; 
     
    display: flex;
   justify-content: center;
   align-items: flex-end; 
   border-radius: 25;

 }     


 .Sidebar-Program-Name-inner-div{
   
    
     height: 30.45% ;
     width : 51.70% ; 
    background-color :  #5A6199; 
    overflow: hidden; 
    display: flex; 
    flex-direction: row; 
    align-items:  "center";  

    justify-content:  "center"; 
   
   

 }


 .Sidebar-profile-Name-div{

     height: 33.94% ;
     width : 100% ; 
    background-color: #5A6199 ; 
    display: flex;
    align-items: center; 
    justify-content: center; 
 
 } 
 



 .Sidebar-Admin-info-div{

  height: 17.20% ;
  width : 100% ; 
 background-color:#5A6199 ; 
 display: flex ; 
 flex-direction: column ; 
 justify-content: space-between;

 }  

.Sidebar-Program-Home-Button-div{
   
  height: 16.97% ;
  width : 100% ; 
 background-color: #5A6199;   
 display: flex;
 align-items: center; 
 justify-content: center;


}
    


.Sidebar-logout-box{

  height: 45% ;
  width : 100% ;  
  display: flex; 

  justify-content: flex-end ; 
  flex-direction: column ;
  align-items: center;
}  
 

.Sidebar-logout-button_div{ 
   


  height: 12% ;
  width: 100% ;
  display: flex ; 
  align-items: center;
  justify-content: center;

}



.Sidebar-logout-button{

  height: 100% ;
  width: 40.41% ; 
  background-color: #5A6199  ; 
  display: flex;  
  overflow: hidden; 
  margin-bottom: 14%;
  border-radius: 15px ;
  
} 
 

.Sidebar-policy-div{
   

  height: 10% ;
  width: 100% ; 
  
 
   
}